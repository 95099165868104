// HTTP CODES
export const HTTP_CODE_NOT_FOUND = 404;
export const HTTP_CODE_BAD_REQUEST = 400;
export const HTTP_CODE_OK = 200;
// API STATUS CODE
export const API_STATUS_CODE_VERIFY_PHONE_NUMBER_SUCCESS = 202;
export const API_STATUS_CODE_SEND_OTP_SUCCESS = 202;
export const API_STATUS_CODE_VERIFY_OTP_SUCCESS = 202;
export const HTTP_CODE_GET_SURVEY_LINK_FAILED = 413;
export const HTTP_CODE_CAMPAIGN_URL_NAME_LOTTERY_UUID_WRONG = 414;
export const HTTP_CODE_LOTTERY_UUID_NOT_EXIST = 409;
export const API_STATUS_CODE_PHONE_NUMBER_NOT_IN_WHITE_LIST = 302;
export const API_STATUS_CODE_RATE_LIMIT_EXCEED = 425;
export const HTTP_CODE_INTERNAL_SERVER_ERROR = 500;
export const API_STATUS_CODE_SEND_OTP_VIA_SNS_FAILED = 459;
export const API_STATUS_CODE_INVALID_OTP_CHANNEL = 460;
export const API_STATUS_CODE_CAMPAIGN_NOT_EXIST = 404;
export const API_STATUS_CODE_PHONE_NUMBER_VALIDATION_FAILED = 458;
export const API_STATUS_CODE_VERIFY_OTP_FAILED = 461;
export const API_STATUS_CODE_IS_EXPIRED = 100;

// LOADING SCREEN DEFAULT VALUE
export const DEFAULT_LOADING_FILL = '#F08B71';
export const DEFAULT_LOADING_WIDTH = '60px';
export const DEFAULT_LOADING_HEIGHT = '60px';

// CAMPAIGN INFO
export const CAMPAIGN_SERIAL_TYPE = 2; // Serial auth
export const CAMPAIGN_PHONE_NUMBER_TYPE = 4; // Phone number auth
export const CAMPAIGN_IS_NOT_EXPIRED = 0;
export const CAMPAIGN_IS_EXPIRED = 1;

// CAMPAIGN FLOW TYPE
export const FLOW_TYPE_REDEMPTION = 'redemption';

// CURRENT STEP
export const WIN_FLAG = 1;
export const LOSE_FLAG = 2;
export const DELAY_LOTTERY_GAME = 3000;
export const NOT_DELAY_LOTTERY_GAME = 0;

// AUTH PAGE
export const SERIAL_CODE_DEFAULT = '0';

// LANGUAGES
export const APP_LANG_DEFAULT = 1; // EN
export const APP_LANG_VN = 2; // VN
export const APP_LANG_ID = 3; // ID
export const APP_LANG_TH = 4; // TH

// CAMPAIGN FLOW
export const FLOW_TYPE_REDIRECT = 2;

// BODY BACKGROUND TYPE
export const BODY_BACKGROUND_TYPE_FILL = 1;
export const BODY_BACKGROUND_TYPE_GRADIENT = 2;
export const BODY_BACKGROUND_TYPE_IMAGE = 3;

// BUTTON BACKGROUND TYPE
export const BUTTON_BACKGROUND_TYPE_FILL = 1;
export const BUTTON_BACKGROUND_TYPE_GRADIENT = 2;

// CONTENT SETTING TYPE
export const CONTENT_SETTING_TYPE_TOP = 'top';
export const CONTENT_SETTING_TYPE_ENTRY = 'entry';

// ENTRY STEP INPUT
export const ENTRY_STEP_INPUT_PHONE_NUMBER = 0;
export const ENTRY_STEP_INPUT_OTP = 1;

// OTP VERIFY
export const DEFAULT_OTP_COUNTDOWN = 15 * 60;

// ROUTER MAPS
export const ROUTER_MAPS = {
    [CAMPAIGN_SERIAL_TYPE]: 'campaign.auth.serial',
    [CAMPAIGN_PHONE_NUMBER_TYPE]: 'campaign.auth.verifyotp',
    [FLOW_TYPE_REDEMPTION]: 'campaign.auth.serial'
};
export const ROUTER_NAME_CAMPAIGN_HOME = 'campaign.home';

// ANIMATION TYPE
export const ANIMATION_TYPE_DEFAULT = 1;
export const ANIMATION_TYPE_CUSTOMIZE = 2;
export const ANIMATION_TYPE_NONE = 3;

// SKIP AGREEMENT FLG
export const NOT_SKIP_AGREEMENT_FLG = 0;
export const IS_SKIP_AGREEMENT_FLG = 1;

// SESSION STORAGE KEY
export const TOP_PAGE_AGREEMENT_TERM_KEY = 'top_page_agreement_term';

// OTP CHANNEL
export const OTP_CHANNEL_SMS = 1;
export const OTP_CHANNEL_ZALO = 2;

// COUNTRY CODE
export const VN_COUNTRY_CODE = 'VN';
export const ID_COUNTRY_CODE = 'ID';

// ZNS AVAILABLE COUNTRY CODES
export const ZNS_AVAILABLE_COUNTRY_CODES = ['VN'];

// APPEARANCE SETTING DEFAULT
export const FOOTER_TEXT = 'Powered by giftee';

// LOTTERY
export const STEP_LOTTERY = 2;

// SURVEY SETTING DEFAULT
export const SURVEY_STATUS_INTERVAL = 2000;
export const SURVEY_TOOL_SURVEY_MONKEY = 1;
export const SURVEY_TOOL_GOOGLE_FORM = 2;
